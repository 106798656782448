<template>
  <div class="invites">
    <v-container fluid>
      <div class="welcome">
        <h1>Untrusted Consignor Invites That Need Approving</h1>
      </div>

      <approvals-table></approvals-table>
    </v-container>
  </div>
</template>

<script>
import UserInvitesTable from "../../../components/user-invites/UserInvitesTable.vue";
import ApprovalsTable from "../../../components/investors/ApprovalsTable.vue";
import userInvites from "../../../api/user-invites";
import Forms from "../../../mixins/Forms";
import eventBus from "../../../eventBus";
const _ = require("lodash");
export default {
  components: { UserInvitesTable, ApprovalsTable },
  mixins: [Forms],
  data() {
    return {
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
  padding-top: 20px;
}

.invites {
  padding: 15px;

  .welcome {
    margin-bottom: 25px;
    display: flex;
    align-items: center;

    h1 {
      flex-grow: 1;
    }
  }
}
</style>